<template>
  <!--begin::PurchaseOrder Internal Listing-->
  <v-container fluid class="pt-0">
    <v-container class="p-0 m-0">
      <v-row>
        <v-col md="3">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            placeholder="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllPurchaseOrders"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.description"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_purchase_orders
                  }}</template>
                  <template v-else>{{
                    item.purchase_order_status_count
                  }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            placeholder="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllPurchaseOrders"
            @keydown.tab="getAllPurchaseOrders"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                placeholder="Dates Range"
                hide-details
                solo
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:change="getAllPurchaseOrders"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="3" class="d-flex">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllPurchaseOrders"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template
            v-if="
              (type == 'project' && detail.status < 4) ||
              (type == 'supplier' && detail.activated)
            "
          >
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:click="createPurchase"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Purchase Order</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="5"
      :dataLoading="dataLoading"
      :rowData="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Purchase Order #</th>
            <th class="simple-table-th" width="300">Basic Details</th>
            <th class="simple-table-th" width="200">Dates</th>
            <th class="simple-table-th" width="200">Supplier</th>
            <th class="simple-table-th" width="200">Status</th>
            <th class="simple-table-th" width="200">Amount</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows"
              v-on:click="
                $router.push(
                  getDefaultRoute('purchase.order.detail', {
                    params: { id: row.id },
                  })
                )
              "
            >
              <td class="simple-table-td">
                <Barcode
                  route="purchase.order.detail"
                  :barcode="row.barcode"
                  :id="row.id"
                ></Barcode>
              </td>
              <td class="simple-table-td" width="100">
                <div class="purchase-order-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference #: </b>
                    <template v-if="row.reference">{{
                      row.reference
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reference #</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Supplier Ref #: </b>
                    <template v-if="row.supplier_reference">{{
                      row.supplier_reference
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Supplier Ref #</em
                      ></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="purchase-order-listing">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Date: </b>
                    <template v-if="row.date">{{
                      formatDate(row.date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Date</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Due Date: </b>
                    <template v-if="row.due_date">{{
                      formatDate(row.due_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Due Date</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Expected Delivery Date: </b>
                    <template v-if="row.expected_delivery_date">{{
                      formatDate(row.expected_delivery_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Expected Delivery Date</em
                      ></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="purchase-order-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ row.supplier.full_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ row.supplier.phone }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ row.supplier.company_name }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <CustomStatus
                  :status="row.status"
                  endpoint="purchase-order/status"
                ></CustomStatus>
              </td>
              <td class="simple-table-td" width="100">
                <div class="purchase-order-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Sub Total: </b>
                    {{ $accountingJS.formatMoney(row.sub_total) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Tax: </b>
                    {{ $accountingJS.formatMoney(row.tax_amount) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total: </b>
                    {{ $accountingJS.formatMoney(row.total) }}
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no purchase order at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::PurchaseOrder Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "purchase-order-internal-list",
  data() {
    return {
      parent: 0,
      customer: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      dataLoading: true,
      momentObject: moment,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          if (this.lodash.isEmpty(param.customer) === false) {
            this.customer = this.lodash.toSafeInteger(param.customer.customer);
          }
          this.getAllPurchaseOrders();
        }
      },
    },
  },
  components: {
    Barcode,
    CustomStatus,
    ListingTable,
  },
  methods: {
    createPurchase() {
      let queryObject = new Object();
      queryObject[this.type] = this.detail.id;
      this.$router.push(
        this.getDefaultRoute("purchase.order.create", {
          query: queryObject,
        })
      );
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllPurchaseOrders();
    },
    getAllPurchaseOrders() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "purchase-order",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
};
</script>
